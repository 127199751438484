export function mapFriendshipToUser(friendship) {
  const user = friendship.user;

  const currentUser = useSessionStore().currentUser;

  user.isCurrentUser = currentUser?.id === user.id;

  delete friendship.user;

  return userMapper({
    ...user,
    friendship
  });
}


export function userMapper(user) {
  return {
    ...user,
    avatarImage: user.images?.preview
      ? updateQueryParameters(user.images?.preview, {width: 500, height: 500, crop: 'fit', rotate: user.images.rotation})
      :user.images?.medium?.url,
    profileClassName: getProfileClassNameFromUUID(user.id)
  }
}
