import imgPhotoPrints from '~/assets/images/projects/project-quick-create-photo-prints.png';
import imgAutoBook from '~/assets/images/projects/project-quick-create-autobook-landscape.png';
import imgAutoMug from '~/assets/images/projects/project-quick-create-automug.png';
import imgAutoTabletop from '~/assets/images/projects/project-quick-create-autotabletop.png';
import imgAutoCoaster from '~/assets/images/projects/project-quick-create-autocoaster.png';
import imgAutoWaterBottle from '~/assets/images/projects/project-quick-create-autowaterbottle.png';
import imgSeamlessAutoBook from '~/assets/images/projects/project-quick-create-seamless-autobook.png';
import imgNotebook from '~/assets/images/projects/project-quick-create-notebook.png';
import imgAutoMagnet from '~/assets/images/projects/project-quick-create-automagnet.png';

export const PROJECT_STATUSES = {
  inProgress: 'in_progress',
  inCart: 'in_cart',
  ordered: 'ordered',
  printable: 'printable'
};

export const photoPrintConf = {
  id: 'photoPrint',
  isAutoPrint: true,
  name: 'Print Photos',

  imageUrl: imgPhotoPrints,
  description: 'Add to your order!',
};

export const autoPrintConfs = {
  photo_books: [
    {
      sku: 'FPPB-0001',
      imageUrl: imgAutoBook,
      name: 'FOREVER AutoBook',
      type: 'Standard Photo Book',
      isContainerPrint: true
    }
  ],

  seamless_layflats: [
    {
      sku: 'AUTOSEAMLESS-001',
      imageUrl: imgSeamlessAutoBook,
      name: 'Seamless AutoBook',
      type: 'Deluxe Seamless Layflat',
      isContainerPrint: true
    }
  ],
  mugs: [
    {
      sku: 'AUTOMUG-001',
      imageUrl: imgAutoMug,
      name: 'Mugs'
    }
  ],
  tabletop_panels: [
    {
      sku: 'AUTOTABLETOP-001',
      imageUrl: imgAutoTabletop,
      name: 'Tabletop Panels'
    }
  ],
  notebooks: [
    {
      sku: 'AUTONOTEBOOK-001',
      imageUrl: imgNotebook,
      name: 'Notebooks'
    }
  ],
  coaster_sets: [
    {
      sku: 'AUTOCOASTERS-001',
      imageUrl: imgAutoCoaster,
      name: 'Coaster Sets'
    }
  ],
  magnets: [
    {
      sku: 'AUTOMAGNET-001',
      imageUrl: imgAutoMagnet,
      name: 'Magnets'
    }
  ],
  stainless_steel_water_bottles: [
    {
      sku: 'AUTOSSWATER-001',
      imageUrl: imgAutoWaterBottle,
      name: 'Water Bottles'
    }
  ]
};
