export const FILE_CONTEXTS = {
  file: 'file',
  gallery: 'gallery',
  album: 'album',
  tag: 'tag',
  container: 'container',
  project: 'project',
  person: 'person',
  deleteBin: 'delete_bin'
};

export const FILE_FILTERS = Object.freeze({
  all: 'all',
  favorites: 'favorites',
  notInAlbum: 'not_in_album',
  notTagged: 'not_tagged',
  photo: 'Photo',
  video: 'Video',
  audio: 'Audio',
  project: 'Project',
  document: 'Document'
});

export const FILE_SORT_OPTIONS = [
  {
    paramName: 'date_taken DESC',
    label: 'Date Taken (Newest)',
    field: 'taken_at',
    adjustToUTC: true,
    isGrouped: true,
    filterDateLabel: 'Date Taken',
    filterOrderParam: 'date_taken DESC'
  },
  {
    paramName: 'date_taken ASC',
    label: 'Date Taken (Oldest)',
    field: 'taken_at',
    adjustToUTC: true,
    isGrouped: true,
    filterDateLabel: 'Date Taken',
    filterOrderParam: 'date_taken ASC'
  },
  {
    paramName: 'upload_date DESC',
    label: 'Upload Date (Newest)',
    field: 'created_at',
    isGrouped: true,
    filterDateLabel: 'Date Uploaded',
    filterOrderParam: 'upload_date DESC'
  },
  {
    paramName: 'upload_date ASC',
    label: 'Upload Date (Oldest)',
    field: 'created_at',
    isGrouped: true,
    filterDateLabel: 'Date Uploaded',
    filterOrderParam: 'upload_date ASC'
  },
  {
    paramName: 'name ASC',
    label: 'Name (A-Z)',
    field: 'name',
    filterDateLabel: 'Date Taken',
    filterOrderParam: 'date_taken DESC'
  },
  {
    paramName: 'name DESC',
    label: 'Name (Z-A)',
    field: 'name',
    filterDateLabel: 'Date Taken',
    filterOrderParam: 'date_taken DESC'
  }
];

export const FILE_SORT_OPTIONS_WITH_CUSTOM = [
  ...FILE_SORT_OPTIONS,
  {
    paramName: 'custom',
    label: 'Custom',
    filterDateLabel: 'Date Taken',
    field: null
  }
];

export const FILE_SORT_OPTION_DELETED = {
  paramName: 'deleted_at DESC',
  field: 'deleted_at',
  isGrouped: true
};

export const FILE_DEFAULT_SORT_OPTION = FILE_SORT_OPTIONS[0];
export const FILE_EDIT_ORIGINAL_SIZE_THRESHOLD = 6;
export const FILE_NAME_MAXLENGTH = 255;

export const STATUSES = Object.freeze({
  finished: 'finished',
  errored: 'errored',
  processing: 'processing',
  previewUnavailable: 'preview_unavailable'
});

export const FILE_FACE_REC_STATUS = {
  not_started: 'not_started',
  indexed: 'indexed',
  complete: 'complete',
  unsupported: 'unsupported'
};

export const FILE_TYPES = Object.freeze({
  project: 'project',
  photo: 'photo',
  document: 'document',
  video: 'video',
  audio: 'audio'
});

export const FILE_GRID_SIZES = {
  sm: 130,
  md: 190,
  lg: 240
};

export const FILE_MAX_IMAGE_ZOOM_SCALE = 2.5;
export const FILE_DEFAULT_IMAGE_ZOOM_SCALE = 1.5;
export const FILE_PAN_THRESHOLD_PERCENT = 15;
export const FILE_SWIPE_DOWN_THRESHOLD_PERCENT = 25;

export const FILE_THUMBS_PREVIEW = {
  width: 80,
  height: 80
};

export const VIDEO_MIN_WIDTH = 300;
export const VIDEO_MIN_HEIGHT = 180;

export const FILE_STAGED_FILES_BEFORE_CURRENT = 1;
export const FILE_STAGED_FILES_AFTER_CURRENT = 3;

export const FILE_FILTER_PANEL_SORT_STORAGE_KEY = 'core-web-ui-filter-panel-sort';
